import Vue from 'vue';
import App from './App.vue';
import router from './router';

import store from './store'
/* element-UI */

import './icon/iconfont.css'
import './plugins/element.js';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import NProgress from 'nprogress' //页面加载顶部进度条
import 'nprogress/nprogress.css'
//import '../public/config.js'

NProgress.configure({
	easing: 'ease', // 动画方式    
	speed: 500, // 递增进度条的速度    
	showSpinner: false, // 是否显示加载ico    
	trickleSpeed: 200, // 自动递增间隔    
	minimum: 0.3 // 初始化时的最小百分比
})

router.beforeEach((to, from, next) => {
	// 每次切换页面时，调用进度条
	NProgress.start();

	next();
});
//跳转结束了
router.afterEach(() => {
	// 在即将进入新的页面组件前，关闭掉进度条
	NProgress.done()
})

import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()


import '@/common/css/all.css';
import '@/common/css/public.css';

import '@/common/js/dialog.js';
import echarts from 'echarts';
import 'echarts/map/js/china'









Vue.prototype.$echarts = echarts;

import Router from 'vue-router'
const originalPush = Router.prototype.replace
Router.prototype.replace = function replace(location, onResolve, onReject) {
	if(onResolve || onReject) {
		return originalPush.call(this, location, onResolve, onReject)
	}
	return originalPush.call(this, location).catch(err => err)
}

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: h => h(App),
	created() {
		let userinformation = JSON.parse(localStorage.getItem('Userinformation'))

		let Token = localStorage.getItem('Token');

		if(userinformation === null||Token===null) {
			localStorage.setItem('Token', null)
			localStorage.setItem('Userinformation', null)
		} else {
			
			this.$store.state.token = Token;
			this.$store.state.nickname = userinformation.nickName;
			this.$store.state.pic = userinformation.pic;

		}
	}

}).$mount('#app')