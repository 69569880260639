import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)
const store = new Vuex.Store({
	state: {
		token:null,
		usercode:'',//账号 
		username:'',//名称
		navBar:[],
		currenthref:'',//当前路由
		iscollapse:false,//菜单折叠
		Lastmenuindex:null,	
		nickname:'',
		pic:'',
	},
	mutations: {
		Token(state, data) {
			state.token = data
		},
		UserCode(state, data){
			state.usercode = data
		},
		UserName(state, data) {
			state.username = data
		},
		toggleSideBar(state, data) {//切换菜单
			state.navBar = data
		},
		currenthRef(state, data){

			state.currenthref = data
		},
		isCollapse(state, data){
			state.iscollapse = data
		},
		lastmenuIndex(state, data){
			state.Lastmenuindex = data
		},
		nickName(state, data){
			state.nickname = data
		},
		picfun(state, data){
			state.pic = data;
			
		}
		
	},
	

})

export default store