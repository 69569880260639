import Vue from 'vue';
import {
	Button,
	Dialog,
	Table,
	TableColumn,
	Input,
	Tree,
	Tooltip,
	DatePicker,
	TimeSelect,
	TimePicker,
	Pagination,
	Form,
	FormItem,
	Row,
	Col,
	Select,
	Option,
	Checkbox,
	CheckboxButton,
	CheckboxGroup,
	Loading,
	MessageBox,
	Message,
	Notification,
	Upload,
	Menu,
	Submenu,
	MenuItem,
	MenuItemGroup,
	Icon,
	Tabs,
	TabPane,
	Link,
	Dropdown,
	DropdownMenu,
	DropdownItem,
	InputNumber,
	Drawer,
	Switch,
	Transfer,
	ColorPicker,
	Radio,
	RadioGroup,
	Image,
	Carousel,
	CarouselItem,
  Cascader,

} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

const element = {
	install: function(Vue) {
		Vue.use(Button)
		Vue.use(Dialog)
		Vue.use(Table)
		Vue.use(TableColumn)
		Vue.use(Input)
		Vue.use(Tree)
		Vue.use(Tooltip)
		Vue.use(DatePicker)
		Vue.use(TimeSelect)
		Vue.use(TimePicker)
		Vue.use(Pagination)
		Vue.use(Form)
		Vue.use(FormItem)
		Vue.use(Row)
		Vue.use(Col)
		Vue.use(Select)
		Vue.use(Option)
		Vue.use(Checkbox)
		Vue.use(CheckboxButton)
		Vue.use(CheckboxGroup)
		Vue.use(Upload)
		Vue.use(Loading.directive)
		Vue.prototype.$loading = Loading.service
		Vue.prototype.$msgbox = MessageBox;
		Vue.prototype.$confirm = MessageBox.confirm;
		Vue.prototype.$message = Message;
		Vue.prototype.$notify = Notification;
		Vue.prototype.$prompt = MessageBox.prompt;
		Vue.use(Menu);
		Vue.use(Submenu);
		Vue.use(MenuItem);
		Vue.use(MenuItemGroup);
		Vue.use(Icon);
		Vue.use(Tabs);
		Vue.use(TabPane);
		Vue.use(TabPane);
		Vue.use(Link);
		Vue.use(Dropdown);
		Vue.use(DropdownMenu);
		Vue.use(DropdownItem);
		Vue.use(InputNumber);
		Vue.use(Drawer);
		Vue.use(Switch);
		Vue.use(Transfer);
		Vue.use(ColorPicker);
		Vue.use(Radio);
		Vue.use(RadioGroup);
		Vue.use(Image);
		Vue.use(Carousel);
		Vue.use(CarouselItem);
		Vue.use(Cascader);
    
    

	}

}
Vue.use(element)
export default element