import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);
const router = new Router({
  routes: [{
      path: '/',
      redirect: 'siginin'
    },
    {
      path: '/siginin',
      component: () =>
        import('../views/siginIn.vue')
    },
    {
      path: '/index',
      component: () =>
        import('@/layout/index.vue'),
      children: [{
          path: '/',
          redirect: 'indexpage'
        },
        {
          path: '/indexpage',
          name: 'indexpage',
          component: () =>
            import('../views/indexPage/indexPage.vue'), //首页
          //meta: { requiresAuth: true, title: "page1",keepAlive:true }
        },
        {
          path: '/groupmanagement',
          name: 'groupmanagement',
          component: () =>
            import('../views/Groupmanagement/index.vue'), //群管理

        },
        {
          path: '/recruitmentInfo',
          name: 'recruitmentInfo',
          component: () =>
            import('../views/RecruitmentInfo/index.vue'), //招工

        },
        {
          path: '/memberregistration',
          name: 'memberregistration',
          component: () =>
            import('../views/Memberregistration/index.vue'), //会员注册

        },
        {
          path: '/withdrawal',
          name: 'withdrawal',
          component: () =>
            import('../views/Withdrawal/index.vue'), //提现列表

        },
        {
          path: '/recordsofcon',
          name: 'recordsofcon',
          component: () =>
            import('../views/Recordsofcon/index.vue'), //消费记录

        },
        {
          path: '/clusteringrecord',
          name: 'clusteringrecord',
          component: () =>
            import('../views/Clusteringrecord/index.vue'), //加群记录

        },
        {
          path: '/factorymanagement',
          name: 'factorymanagement',
          component: () =>
            import('../views/Factorymanagement/index.vue'), //工厂管理

        },
        {
          path: '/registrationrecord',
          name: 'registrationrecord',
          component: () =>
            import('../views/Registrationrecord/index.vue'), //报名记录

        },

        {
          path: '/typeofwork',
          name: 'typeofwork',
          component: () =>
            import('../views/Typeofwork/index.vue'), //工种类型管理

        },
        {
          path: '/promotionhall',
          name: 'promotionhall',
          component: () =>
            import('../views/Promotionhall/index.vue'), //推广大厅管理

        },
 {
          path: '/entrustedwork',
          name: 'entrustedwork',
          component: () =>
            import('../views/Entrustedwork/index.vue'), //委托找活

        },







      ]

    },

  ]
})

export default router;
